import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import Footer from '@oup/shared-front-end/src/components/Wizard/Footer/Footer.js';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';

import withLocalizedContent from '../../../language/withLocalizedContent';

function ChooseRoleWizardFooter({ localizedContent: { hubGlossary: hubContent } }) {
  const { nextStep, nextStepDisabled } = useWizard();

  return (
    <Footer
      primaryButtonAction={nextStep}
      primaryButtonLabel={hubContent.next}
      primaryButtonAttributes={{
        disabled: nextStepDisabled,
        dataAttributes: {
          testid: 'SELF_REGISTERED_USER_ONBOARDING_WIZARD_NEXT_BUTTON'
        }
      }}
    />
  );
}

ChooseRoleWizardFooter.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default compose(withLocalizedContent('hubGlossary'))(ChooseRoleWizardFooter);
