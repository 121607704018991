import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import Footer from '@oup/shared-front-end/src/components/Wizard/Footer/Footer.js';

function CreateClassWizardFooter({ content, classNameValue, classCreationPending }) {
  const { isLastStep, nextStep, nextStepDisabled } = useWizard();
  const setPrimaryButtonLabel = () => (isLastStep ? `${content.go_to} ${classNameValue}` : content.next);

  return (
    <Footer
      primaryButtonAction={nextStep}
      primaryButtonLabel={setPrimaryButtonLabel()}
      primaryButtonAttributes={{
        disabled: nextStepDisabled,
        loading: classCreationPending,
        dataAttributes: {
          testid: 'CREATE_CLASS_WIZARD_NEXT_BUTTON'
        }
      }}
    />
  );
}

CreateClassWizardFooter.propTypes = {
  content: PropTypes.object.isRequired,
  classCreationPending: PropTypes.bool,
  classNameValue: PropTypes.string
};

export default connect(
  ({ classOnboardingWizard, classroomCreate }) => ({
    classNameValue: classroomCreate.classroomNameValue,
    classCreationPending: classOnboardingWizard.classNamePending
  }),
  null
)(CreateClassWizardFooter);
