import { PLACEMENT_NOTIFICATIONS_TYPES } from '@oup/shared-node-browser/constants.js';

const { BEFORE_STARTS, BEFORE_ENDS } = PLACEMENT_NOTIFICATIONS_TYPES;

export const DEFAULT_REMINDERS = [
  { timePeriod: 'select', notificationEvent: BEFORE_STARTS },
  { timePeriod: 'select', notificationEvent: BEFORE_ENDS }
];

export const INTERVAL_OPTIONS = [
  {
    value: 'select',
    text: 'Please select'
  },
  {
    value: 12,
    text: '12 hours'
  },
  {
    value: 24,
    text: '1 day'
  },
  {
    value: 72,
    text: '3 days'
  },
  {
    value: 168,
    text: '1 week'
  }
];

export const MOMENT_OPTIONS = [
  {
    value: BEFORE_STARTS,
    text: 'before test session starts'
  },
  {
    value: BEFORE_ENDS,
    text: 'before test session ends'
  }
];
