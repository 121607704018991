import { isEqual } from 'lodash';
import { useState, useEffect, useRef } from 'react';
import { DEFAULT_REMINDERS } from '../../opt/notifications';

const useNotifications = ({ placementTest, editedPlacementTest, setEditedPlacementTestAction }) => {
  const [state, setState] = useState({
    sendEmailsToStudents: false,
    notificationReminders: [],
    isValid: false,
    isTouched: false
  });

  const initialStateRef = useRef(null);

  useEffect(() => {
    if (!initialStateRef.current) {
      let initialSendEmails = editedPlacementTest?.sendEmailsToStudents ?? placementTest?.sendEmailsToStudents ?? false;
      let initialReminders = editedPlacementTest?.notificationReminders ?? placementTest?.notificationReminders ?? [];
      const placementTestType = editedPlacementTest?.placementTestType ?? placementTest?.placementTestType;

      if (initialSendEmails && initialReminders.length === 0) {
        if (placementTestType === 'OPT_YL') {
          initialReminders = [];
        } else {
          initialSendEmails = true;
          initialReminders = DEFAULT_REMINDERS;
        }
      }

      const initialStateData = {
        sendEmailsToStudents: initialSendEmails,
        notificationReminders: initialReminders
      };

      initialStateRef.current = initialStateData;

      setState({
        ...initialStateData,
        isValid: initialSendEmails ? !initialReminders.some(item => item.timePeriod === 'select') : true,
        isTouched: false
      });
    }
  }, [placementTest, editedPlacementTest]);

  const onStateUpdate = updates => {
    const updatedState = { ...state, ...updates };

    if ('sendEmailsToStudents' in updates || 'notificationReminders' in updates) {
      updatedState.isValid = updatedState.sendEmailsToStudents
        ? !updatedState.notificationReminders.some(item => item.timePeriod === 'select')
        : true;
    }

    const isTouched = !isEqual(updatedState, initialStateRef.current);

    setState({ ...updatedState, isTouched });
    setEditedPlacementTestAction({
      sendEmailsToStudents: updatedState.sendEmailsToStudents,
      notificationReminders: updatedState.notificationReminders
    });
  };

  return { initialStateRef, state, setState, onStateUpdate };
};

export default useNotifications;
