import React from 'react';
import { compose } from 'redux';

import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Styles
import styles from './MyDashboardStudentView.scss';

// Components
import LastOpened from './LastOpened';
import WordOfTheDay from './WordOfTheDay';
import HubEmptyStateRestyle from '../../components/HubEmptyStateRestyle/HubEmptyStateRestyle';
import { HubIllustrationAltText, HubIllustrationConstants } from '../../globals/hubConstants';
import withLocalizedContent from '../../language/withLocalizedContent';

function MyDashboardStudentView({ localizedContent: { hubGlossary: hubContent } }) {
  const currentOrganisationId = useSelector(state => state.identity.currentOrganisationId);
  const { isPlacementCentre = false } = useSelector(state => state.organisations.data[currentOrganisationId]);

  return (
    <div className={styles.containerWrapper}>
      <div className={`${styles.dashboardContainer} ${isPlacementCentre ? styles.dashboardWithTasks : ''}`}>
        {isPlacementCentre && (
          <div className={[styles.myTasksDashboard, styles.dashboardItemMedium].join(' ')}>
            <HubEmptyStateRestyle
              iconSrc={HubIllustrationConstants.DIGITAL_LIBRARY}
              iconAlt={HubIllustrationAltText.DIGITAL_LIBRARY}
              title={hubContent.tests_or_tasks}
              link={{
                link: '/myTasks',
                text: <b>{hubContent.go_to_tasks}</b>,
                openInNewTab: false
              }}
            />
          </div>
        )}
        <div className={[styles.lastOpenArea, styles.dashboardItemMedium].join(' ')}>
          <LastOpened />
        </div>

        <div className={styles.wordOfTheDay}>
          <WordOfTheDay />
        </div>
      </div>
    </div>
  );
}

export default compose(withLocalizedContent('hubGlossary'))(MyDashboardStudentView);

MyDashboardStudentView.propTypes = {
  localizedContent: PropTypes.object.isRequired
};
