import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Wizard } from '@oup/shared-front-end/src/components/Wizard';
import { connect } from 'react-redux';

import { openJoinClassWithCodeModal, showSelfSelectRoleModal } from '../../../redux/actions/hubUi';

import OnboardingStudentSetupWizardFooter from './OnboardingStudentSetupWizardFooter';
import WizardHeader from '../WizardHeader.js';

import OnboardingWizardWelcome from '../OnboardingWizardWelcome/OnboardingWizardWelcome.js';
import ChooseStudentCode from '../ChooseStudentCode/ChooseStudentCode.js';
import EnterACodeModal from '../EnterACodeModal/EnterACodeModal.js';

// Why have we drilled this first name prop down through the wizard components?
function OnboardingStudentSetupWizard({ firstName, openJoinClassWithCodeModalAction, showSelfSelectRoleModalAction }) {
  const [isEnterACodeModalOpen, setIsEnterACodeModalOpen] = useState(false);

  const enterACodeModalHandleNext = () => {
    setIsEnterACodeModalOpen(false);
    showSelfSelectRoleModalAction(false);
    openJoinClassWithCodeModalAction();
  };

  return (
    <>
      <Wizard header={<WizardHeader />} footer={<OnboardingStudentSetupWizardFooter />}>
        <OnboardingWizardWelcome firstName={firstName} />
        <ChooseStudentCode />
      </Wizard>
      <EnterACodeModal
        isOpen={isEnterACodeModalOpen}
        onClose={() => setIsEnterACodeModalOpen(false)}
        onNext={enterACodeModalHandleNext}
      />
    </>
  );
}

OnboardingStudentSetupWizard.propTypes = {
  firstName: PropTypes.string.isRequired,
  openJoinClassWithCodeModalAction: PropTypes.func.isRequired,
  showSelfSelectRoleModalAction: PropTypes.func.isRequired
};

export default connect(null, {
  openJoinClassWithCodeModalAction: openJoinClassWithCodeModal,
  showSelfSelectRoleModalAction: showSelfSelectRoleModal
})(OnboardingStudentSetupWizard);
