import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { has } from 'lodash';
// Utils
import Button from '@oup/shared-front-end/src/components/Button';
import IconWarningCircle from '@oup/shared-front-end/src/svg/oup/icon-warning-circle-24.svg';
import { ICON_DOWNLOAD } from '@oup/shared-front-end/src/svg/oup';
import breakpoints from '../../../globals/breakpoints';
import getSideNavItems from '../Services/getNavigationItems';
import { getLimitTypeReached, limitTypes } from '../Utils/getOrgUsageLimits';
import { isLtiMode } from '../../../utils/platform';
// Components
import Link from '../../../components/Link/Link';
import Thumbnail, { SIZES as thumbnailSizes } from '../../../components/Thumbnail/Thumbnail';
import SecondaryLevelContainer from './SecondaryLevelContainer';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
// Styles
import styles from './HubSideNavbar.scss';
import { featureIsEnabled, getPlatformBaseUrl } from '../../../globals/envSettings';
import { HubLayoutConstants } from '../../../globals/hubConstants.js';
import SideNavbarLoader from '../../../components/SkeletonLoader/Hub/SideNavbarLoader.js';
import { isTeacherInOrgOrSelfSelected } from '../../../globals/userRoles.js';

const { TARGET_USERTYPE } = HubLayoutConstants;

class HubSideNavbar extends Component {
  static hasOfflineProduct = myCourses => {
    const courses = Object.values(myCourses);
    let products = [];
    let foundOfflineProduct = false;
    for (let courseKey = 0; courseKey < courses.length; courseKey += 1) {
      products = Object.values(courses[courseKey].products.data);
      for (let productKey = 0; productKey < products.length; productKey += 1) {
        const expired = new Date(products[productKey].expiryDate) < new Date();
        if (
          products[productKey].offline_content &&
          products[productKey].hasLicence &&
          products[productKey].productUserRole === TARGET_USERTYPE.TEACHER &&
          !expired
        ) {
          foundOfflineProduct = true;
        }
      }
    }

    return foundOfflineProduct;
  };

  constructor(props) {
    super(props);
    this.state = {
      hovered: false,
      toggled: '',
      footerVisible: false
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    const {
      props: { isOpen }
    } = this;

    // If side nav has been closed from the hamburger button then collapse the secondary navigation
    if (prevProps.isOpen !== isOpen && isOpen === false) {
      this.collapseNavItems();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const footer = document.querySelector('footer');
    const observer = new window.IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          this.setState({ footerVisible: true });
          return;
        }
        this.setState({ footerVisible: false });
      },
      {
        root: null,
        threshold: 0.1
      }
    );

    observer.observe(footer);
  }

  handleHoverOn = () => this.setState({ hovered: true });

  handleHoverOff = () => this.setState({ hovered: false });

  // Collapse nav items
  collapseNavItems = () => this.setState({ toggled: '' });

  handleMouseEnter = () => this.handleHoverOn();

  handleMouseLeave = () => {
    const {
      props: { isOpen }
    } = this;

    this.handleHoverOff();

    if (!isOpen) this.collapseNavItems();
  };

  handleSelectNavItem = (event, { canToggle, path }) => {
    const {
      props: { closeNavigation, breakpoint }
    } = this;

    // Toggle selected nav item
    if (canToggle) this.setState(state => ({ toggled: state.toggled === path ? '' : path }));

    if (!canToggle) {
      this.handleHoverOff();
      this.collapseNavItems();
    }

    if (breakpoint !== breakpoints.LG && !canToggle) closeNavigation();
  };

  handleSelectSecondaryLevel = () => {
    const {
      props: { breakpoint, closeNavigation },
      state: { hovered }
    } = this;
    // Always handleHoverOff to avoid other bugs
    if (hovered) this.handleHoverOff();

    // Always collapse nav items on select secondary level
    this.collapseNavItems();

    // If breakpoint not LG always close navigation, <1180 px
    if (breakpoint !== breakpoints.LG) closeNavigation();
  };

  render() {
    const {
      props: {
        isOpen,
        pathname,
        hubContent,
        userRole,
        organization,
        openRedeemModal,
        openEnterCodeModal,
        openCreateClassModal,
        openJoinClassWithCodeModal,
        openOnboardingWizard,
        currentOrganisationLti,
        classesCount,
        activeUserCount,
        userPlatformStatistics,
        userId,
        myCourses,
        areCoursesLoading,
        areClassesLoading
      },
      state: { toggled, hovered, footerVisible }
    } = this;

    const navItemsActions = {
      openRedeemModal,
      openEnterCodeModal,
      openCreateClassModal,
      openJoinClassWithCodeModal,
      openOnboardingWizard
    };
    const limitTypeReached = getLimitTypeReached(classesCount, activeUserCount);
    const userHasLicences =
      has(userPlatformStatistics, 'error') &&
      !userPlatformStatistics.error &&
      Object.keys(userPlatformStatistics.redeemed).length > 0;
    const sideNavItems = getSideNavItems(
      hubContent,
      userRole,
      organization,
      navItemsActions,
      currentOrganisationLti,
      userHasLicences
    );
    const isLoading = areCoursesLoading || areClassesLoading;

    const foundOfflineProduct = HubSideNavbar.hasOfflineProduct(myCourses);

    return (
      <nav
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        className={classnames(styles.sideNav, { [styles.open]: isOpen }, { [styles.hovered]: hovered })}
        aria-label="Secondary navigation"
      >
        <ul>
          {isLoading ? (
            <SideNavbarLoader isOpen={isOpen} />
          ) : (
            sideNavItems.map(item => {
              if (item.disabled) return null;
              if (item.isButton && !isLtiMode()) {
                return (
                  <li key={item.id} className={styles.enterCodeButton}>
                    <Button {...item} icon={{ component: <SVGIcon glyph={item.icon} /> }} />
                  </li>
                );
              }
              if (!item.canToggle && item.showTab) {
                return (
                  <li
                    key={item.id}
                    onMouseEnter={this.handleMouseEnter}
                    className={item.tasksNumber ? styles.taskBadgeDisplayed : ''}
                  >
                    <Link
                      onClick={event => this.handleSelectNavItem(event, item)}
                      id={item.id}
                      className={classnames([styles.sideNavLink], {
                        [styles.sideNavItemSelected]: pathname.startsWith(item.path)
                      })}
                      to={item.linkTo}
                      tabIndex={item.tabindex}
                      dataTestId={item.testHook}
                      aria-label={item.ariaLabel}
                      onKeyDown={event => {
                        if (event.key === 'Enter') {
                          this.handleSelectNavItem(event, item);
                        }
                      }}
                    >
                      <div className={styles.thumbnailContainer}>
                        <SVGIcon glyph={item.icon} />
                      </div>
                      <div
                        className={
                          item.navSubTitle
                            ? `${styles.itemDetailsContainer} ${styles.WithSubtitle}`
                            : styles.itemDetailsContainer
                        }
                      >
                        <div className={styles.itemTitle}>{item.navText}</div>
                        {item.navSubTitle && <small className={styles.itemSubTitle}>{item.navSubTitle}</small>}
                      </div>
                      {item.tasksNumber ? <div className={styles.tasksBadge}>{item.tasksNumber}</div> : null}
                      {item.navText === hubContent.my_organization && limitTypeReached !== limitTypes.NONE && (
                        <div className={styles.warningIconContainer}>
                          <IconWarningCircle />
                        </div>
                      )}
                    </Link>
                  </li>
                );
              }

              if (item.canToggle && item.showTab) {
                return (
                  <li key={item.id} onMouseEnter={this.handleMouseEnter}>
                    <div
                      role="button"
                      onClick={event => this.handleSelectNavItem(event, item)}
                      id={item.id}
                      className={classnames([styles.sideNavLink], {
                        [styles.sideNavItemSelected]: pathname.startsWith(item.path)
                      })}
                      tabIndex={item.tabindex}
                      data-testid={item.testHook}
                      aria-label={`${item.ariaLabel} ${toggled === item.path ? 'expanded' : 'collapsed'}`}
                      onKeyDown={event => {
                        if (event.key === 'Enter') {
                          this.handleSelectNavItem(event, item);
                        }
                      }}
                    >
                      <div className={styles.thumbnailContainer}>
                        <SVGIcon glyph={item.icon} />
                      </div>

                      <div
                        className={
                          item.navSubTitle
                            ? `${styles.itemDetailsContainer} ${styles.WithSubtitle}`
                            : styles.itemDetailsContainer
                        }
                      >
                        <div className={styles.itemTitle}>{item.navText}</div>
                        {item.navSubTitle && <small className={styles.itemSubTitle}>{item.navSubTitle}</small>}
                        <div className={classnames(styles.carat, { [styles.caratOpen]: toggled === item.path })}>
                          <Thumbnail glyph={GLYPHS.ICON_CARAT} size={thumbnailSizes.SMALL} />
                        </div>
                      </div>
                    </div>
                    <SecondaryLevelContainer
                      isSelected={toggled === item.path}
                      hubContent={hubContent}
                      handleSelectSecondaryLevel={this.handleSelectSecondaryLevel}
                      toggledItems={item.toggledItems}
                      path={item.path}
                      userRole={userRole}
                    />
                  </li>
                );
              }

              return null;
            })
          )}
        </ul>
        {featureIsEnabled('hub-offline-downloads') && isTeacherInOrgOrSelfSelected(userRole) && foundOfflineProduct && (
          <Button
            variant="outline"
            size="base"
            text={hubContent.my_downloads}
            icon={{ component: <ICON_DOWNLOAD /> }}
            className={classnames(
              styles.myDownloads,
              { [styles.open]: isOpen },
              { [styles.footerVisible]: footerVisible }
            )}
            onClick={async () => {
              window.open(`${getPlatformBaseUrl('ocp')}/downloads/${userId}`, '_blank').focus();
            }}
          />
        )}
      </nav>
    );
  }
}

HubSideNavbar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
  breakpoint: PropTypes.string.isRequired,
  closeNavigation: PropTypes.func.isRequired,
  openRedeemModal: PropTypes.func.isRequired,
  openEnterCodeModal: PropTypes.func.isRequired,
  openCreateClassModal: PropTypes.func,
  openJoinClassWithCodeModal: PropTypes.func.isRequired,
  hubContent: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  organization: PropTypes.object,
  openOnboardingWizard: PropTypes.func,
  currentOrganisationLti: PropTypes.bool,
  classesCount: PropTypes.number,
  activeUserCount: PropTypes.number,
  userPlatformStatistics: PropTypes.object,
  userId: PropTypes.string,
  myCourses: PropTypes.object,
  areCoursesLoading: PropTypes.bool,
  areClassesLoading: PropTypes.bool
};

export default compose(
  connect(
    ({
      orgUsage: { classesCount, activeUserCount },
      userPlatformStatistics,
      userProfile: { userId },
      hubCourses: { courses: myCourses, loading: hubCoursesLoading },
      search
    }) => ({
      classesCount,
      activeUserCount,
      userPlatformStatistics,
      userId,
      myCourses,
      areCoursesLoading: hubCoursesLoading,
      areClassesLoading: search?.profileClasses?.loading
    })
  )
)(HubSideNavbar);
