import PropTypes from 'prop-types';
import { pick, omit } from 'lodash';
import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { connect, useSelector } from 'react-redux';
import ValidationMessage from '@oup/shared-front-end/src/components/ValidationMessage';
import Button from '@oup/shared-front-end/src/components/Button';
import Footer from '@oup/shared-front-end/src/components/Wizard/Footer/Footer.js';
import HubEmptyStateRestyle from '../../../HubEmptyStateRestyle/HubEmptyStateRestyle';
import ScrollContainer from '../../../ScrollContainer/ScrollContainer.js';
import SVGIcon, { GLYPHS } from '../../../SVGIcon/SVGIcon';
import PanelHeading from '../../../PanelHeading/PanelHeading.js';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import PanelSearchControl from '../../../PanelSearchControl/PanelSearchControl';
import PaginationButtons from '../../../PaginationButtons/PaginationButtons.js';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../../globals/hubConstants';
import styles from './ManageAddedStudentsPanel.scss';
import { getPlacementTestSessionRequest } from '../../../../redux/actions/placementTestOnboardingWizard.js';
import UserListing, { ListingTypes } from '../../../UserListing/UserListing.js';
import { setSelectedStudents as setSelectedStudentsToRemove } from '../../../../redux/reducers/removeStudentsFromClassroom.js';
import { getStudentsInTestSession, setStudentsSorting } from '../../../../redux/actions/placementTests';
import { searchPlacementStudentsSortOptions } from '../../../../globals/searchFilters';

function ManageAddedStudentsPanel({
  placementTestSessionName,
  cancelPanel,
  onAddStudents,
  onRemoveStudents,
  studentsDetails = {},
  totalResults = 0,
  failed,
  isManageSessionEditPanel,
  localizedContent: {
    placementTests: placementTestsContent,
    studentSelectionPanel: studentSelectionPanelContent,
    hubGlossary: hubContent
  },
  getPlacementTestSessionRequestAction,
  getStudentsInTestSessionAction,
  setStudentsSortingAction,
  currentPage,
  paginate,
  numberOfLicenses = 0,
  studentsInTestSession,
  selectedStudentsIds,
  selectedStudentsDetails,
  setSelectedStudentsAction
}) {
  const {
    currentOrganisationId,
    placementTestSessionId,
    studentsBulkImportIsInProgress,
    selectedSortOption
  } = useSelector(({ placementTestSessionCreate, placementOnboardingWizard, identity, loadPlacementTestsReducer }) => ({
    currentOrganisationId: identity.currentOrganisationId,
    placementTestSessionId:
      placementTestSessionCreate.placementTestSessionId || placementOnboardingWizard.placementTestSessionIdCreated,
    studentsBulkImportIsInProgress: placementTestSessionCreate.placementTest.studentsBulkImportIsInProgress,
    selectedSortOption: loadPlacementTestsReducer.studentsSortedBy
  }));
  const [selectedStudents, setSelectedStudents] = useState({
    ids: selectedStudentsIds,
    details: selectedStudentsDetails
  });

  const handleSort = value => {
    setStudentsSortingAction(value[0]);
    getStudentsInTestSessionAction({ classId: placementTestSessionId, sortBy: value[0] });
  };

  const addOrRemoveStudentsToSelected = id => {
    setSelectedStudents(prev => {
      if (!prev.ids.includes(id)) {
        const newIdsList = [...prev.ids, id];
        const newDetailsList = { ...prev.details, ...pick(studentsInTestSession, id) };
        return { ids: newIdsList, details: newDetailsList };
      }

      if (prev.ids.includes(id)) {
        const newIdsList = prev.ids.filter(item => item !== id);
        const newDetailsList = omit(prev.details, id);
        return { ids: newIdsList, details: newDetailsList };
      }

      return prev;
    });
  };

  useEffect(() => {
    setSelectedStudentsAction(selectedStudents.ids, selectedStudents.details);
  }, [selectedStudents.ids]);

  useEffect(() => {
    const interval = setInterval(() => {
      getPlacementTestSessionRequestAction({ orgId: currentOrganisationId, sessionId: placementTestSessionId });
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const sessionHasNoLicences = numberOfLicenses === 0;
  return (
    <ScrollContainer
      headerContent={
        <>
          <PanelHeading
            title={
              isManageSessionEditPanel
                ? placementTestsContent.manage_session_manage_students_panel_title
                : placementTestsContent.manage_added_students_panel_title
            }
            subtitle={
              isManageSessionEditPanel
                ? placementTestsContent.manage_session_manage_students_panel_subtitle.replace(
                    '[sessionName]',
                    `<b>${placementTestSessionName}</b>`
                  )
                : placementTestsContent.manage_added_students_panel_subtitle.replace(
                    '[sessionName]',
                    `<b>${placementTestSessionName}</b>`
                  )
            }
            customClassName={styles.headingStyle}
          />
          {totalResults ? (
            <PanelSearchControl
              sortOptions={searchPlacementStudentsSortOptions(selectedSortOption)}
              setSortFilter={handleSort}
              placeholder={studentSelectionPanelContent.search_bar_placeholder_secondary}
              customClassName={styles.searchControl}
            />
          ) : null}
        </>
      }
      footerContent={
        <Footer
          primaryButtonLabel={placementTestsContent.placement_test_remove_students}
          secondaryButtonLabel={hubContent.cancel}
          primaryButtonAction={() => {
            onRemoveStudents();
          }}
          secondaryButtonAction={cancelPanel}
          primaryButtonAttributes={{
            dataAttributes: {
              testid: 'PLACEMENT_TEST_PANEL_MANAGE_STUDENTS_REMOVE_STUDENTS_BUTTON'
            },
            disabled: !selectedStudentsIds?.length
          }}
          secondaryButtonAttributes={{
            dataAttributes: {
              testid: 'PLACEMENT_TEST_PANEL_MANAGE_STUDENTS_REMOVE_STUDENTS_CANCEL_BUTTON'
            }
          }}
        />
      }
    >
      {!failed && totalResults ? (
        <div className={styles.manageAddedStudentsContainer}>
          {studentsBulkImportIsInProgress && (
            <ValidationMessage state="information" className={styles.validationMessage}>
              {placementTestsContent.manage_added_students_pannel_bulkimport_in_progress}
            </ValidationMessage>
          )}
          <div className={styles.manageStudentsList}>
            <div className={styles.manageAddedStudentAddBtnContainer}>
              <Button
                onClick={onAddStudents}
                text={placementTestsContent.placement_test_add_students}
                variant="outline"
                size="small"
                icon={{ component: <SVGIcon glyph={GLYPHS.ICON_PLUS} /> }}
                dataAttributes={{ testid: 'MANAGE_SESSION_MANAGE_STUDENTS_PANNEL_ADD_BTN' }}
                disabled={sessionHasNoLicences}
                className={styles.addStudentBtn}
              />
            </div>

            <UserListing
              items={studentsDetails.items}
              selectable={studentsDetails.selectableIds}
              selectAllEnabled
              heading="Students"
              listingType={ListingTypes.PANEL}
              selectedItems={selectedStudents.ids}
              onItemSelect={addOrRemoveStudentsToSelected}
            />
          </div>

          {totalResults > 10 ? (
            <div className="gin-top2">
              <PaginationButtons
                idPrefix="placementTestStudentsSearch"
                value={currentPage}
                numberOfPages={Math.ceil(totalResults / 10)}
                onClick={paginate}
                aria={{ 'aria-controls': 'searchPlacementTestStudentsResults' }}
              />
            </div>
          ) : null}
        </div>
      ) : (
        <>
          <HubEmptyStateRestyle
            iconSrc={HubIllustrationConstants.SEARCH}
            iconAlt={HubIllustrationAltText.SEARCH}
            title={studentSelectionPanelContent.no_students_in_org_title}
            bodyText={placementTestsContent.manage_added_students_panel_empty_state_message}
          />
          <div className={styles.centerAddStudentsBtn}>
            <Button
              onClick={onAddStudents}
              text={placementTestsContent.placement_test_add_students}
              variant="outline"
              size="small"
              icon={{ component: <SVGIcon glyph={GLYPHS.ICON_PLUS} /> }}
              dataAttributes={{ testid: 'MANAGE_SESSION_MANAGE_STUDENTS_PANNEL_ADD_BTN' }}
              disabled={sessionHasNoLicences}
              className={styles.addStudentBtn}
            />
          </div>
        </>
      )}
    </ScrollContainer>
  );
}

ManageAddedStudentsPanel.propTypes = {
  cancelPanel: PropTypes.func,
  onAddStudents: PropTypes.func,
  onRemoveStudents: PropTypes.func,
  placementTestSessionName: PropTypes.string.isRequired,
  failed: PropTypes.bool.isRequired,
  studentsDetails: PropTypes.object,
  isManageSessionEditPanel: PropTypes.bool,
  localizedContent: PropTypes.object.isRequired,
  getPlacementTestSessionRequestAction: PropTypes.func,
  getStudentsInTestSessionAction: PropTypes.func.isRequired,
  setStudentsSortingAction: PropTypes.func.isRequired,
  totalResults: PropTypes.number,
  currentPage: PropTypes.number,
  paginate: PropTypes.func,
  numberOfLicenses: PropTypes.number,
  studentsInTestSession: PropTypes.array,
  selectedStudentsIds: PropTypes.array,
  selectedStudentsDetails: PropTypes.object,
  setSelectedStudentsAction: PropTypes.func
};

export default compose(
  withLocalizedContent('hubGlossary', 'placementTests', 'studentSelectionPanel'),
  connect(
    ({ placementTestSessionCreate, loadPlacementTestsReducer, removeStudentsFromClassroom }) => ({
      numberOfLicenses: placementTestSessionCreate.numberOfLicenses,
      studentsInTestSession: loadPlacementTestsReducer?.studentsInTestSession?.students?.items,
      selectedStudentsIds: removeStudentsFromClassroom.selectedStudentsIds,
      selectedStudentsDetails: removeStudentsFromClassroom.selectedStudentsDetails
    }),
    {
      getPlacementTestSessionRequestAction: getPlacementTestSessionRequest,
      getStudentsInTestSessionAction: getStudentsInTestSession,
      setSelectedStudentsAction: setSelectedStudentsToRemove,
      setStudentsSortingAction: setStudentsSorting
    }
  )
)(ManageAddedStudentsPanel);
