import React from 'react';
import { pick } from 'lodash';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { ICON_LEFT } from '@oup/shared-front-end/src/svg/oup';
import Footer from '@oup/shared-front-end/src/components/Wizard/Footer/Footer.js';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import { connect } from 'react-redux';
import withLocalizedContent from '../../language/withLocalizedContent';

function OrganizationSetupWizardFooter({ localizedContent: { hubGlossary: hubContent }, submitting }) {
  const { nextStep, prevStep, nextStepDisabled, isLastStep } = useWizard();

  const setPrimaryButtonLabel = () => {
    if (isLastStep) return hubContent.done;
    if (submitting) return '';

    return hubContent.next;
  };

  return (
    <Footer
      primaryButtonAction={nextStep}
      primaryButtonLabel={setPrimaryButtonLabel()}
      primaryButtonAttributes={{
        disabled: nextStepDisabled,
        loading: !!submitting,
        dataAttributes: {
          testid: 'SELF_REGISTERED_USER_ONBOARDING_WIZARD_NEXT_BUTTON'
        }
      }}
      secondaryButtonAction={isLastStep ? prevStep : null}
      secondaryButtonLabel={isLastStep ? hubContent.button_back_text : null}
      secondaryButtonAttributes={{
        icon: {
          component: <ICON_LEFT />
        }
      }}
    />
  );
}

OrganizationSetupWizardFooter.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  submitting: PropTypes.bool
};

export default compose(
  withLocalizedContent('hubGlossary'),
  connect(state => ({
    ...pick(state.orgRegistration, ['submitting'])
  }))
)(OrganizationSetupWizardFooter);
