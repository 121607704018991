import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@oup/shared-front-end/src/components/Button';
import ValidationMessage from '@oup/shared-front-end/src/components/ValidationMessage';
import Toggle from '@oup/shared-front-end/src/components/Toggle';
import SelectInputs from './SelectInputs/SelectInputs';
import Tooltip from '../../Tooltip/Tooltip';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { DEFAULT_REMINDERS, INTERVAL_OPTIONS, MOMENT_OPTIONS } from '../../../utils/opt/notifications';
import style from './NotificationsAndReminders.scss';

function NotificationsAndReminders({
  localizedContent: { placementTests: placementTestsContent },
  sendEmailsToStudents,
  notificationReminders,
  initialState,
  onStateUpdate
}) {
  const handleSelectChange = useCallback(
    (notificationIndex, eventName, value) => {
      const updatedReminders = [...notificationReminders];
      updatedReminders[notificationIndex] = {
        ...updatedReminders[notificationIndex],
        [eventName]: eventName === 'timePeriod' && value !== 'select' ? Number(value) : value
      };
      onStateUpdate({ notificationReminders: updatedReminders });
    },
    [notificationReminders, onStateUpdate]
  );

  const removeReminder = notificationIndex => {
    const updatedReminders = notificationReminders.filter((_, i) => i !== notificationIndex);
    onStateUpdate({ notificationReminders: updatedReminders });
  };

  const addReminder = () => {
    const updatedReminders = [...notificationReminders, DEFAULT_REMINDERS[0]];
    onStateUpdate({ notificationReminders: updatedReminders });
  };

  const onToggle = useCallback(() => {
    const toggled = !sendEmailsToStudents;
    let notifications = [];

    if (toggled) {
      if (notificationReminders.length) {
        notifications = notificationReminders;
      } else if (initialState?.notificationReminders.length) {
        notifications = initialState.notificationReminders;
      }
    }
    onStateUpdate({
      sendEmailsToStudents: toggled,
      notificationReminders: notifications
    });
  }, [sendEmailsToStudents, notificationReminders, onStateUpdate, initialState]);

  const ToggleComponent = useCallback(() => <Toggle onChange={onToggle} toggled={sendEmailsToStudents} />, [
    sendEmailsToStudents
  ]);

  return (
    <div className={style.notificationsColumn}>
      <div className={style.customiseToggle}>
        <ToggleComponent />
        <span className={style.notificationText}>
          {placementTestsContent.placement_test_notification_page_toggle_label}
        </span>
        <Tooltip
          className={style.orgStatusLicenceItemTooltip}
          title={placementTestsContent.placement_test_notification_page_tooltip_text}
        >
          <SVGIcon glyph={GLYPHS.ICON_HELP_CIRCLE} className={style.infoIcon} />
        </Tooltip>
      </div>
      {sendEmailsToStudents && (
        <>
          <div className={style.selectionContainer}>
            {notificationReminders.map((reminder, index) => (
              <SelectInputs
                label={index !== 0 ? 'and' : 'Set reminders'}
                intervalValue={reminder.timePeriod}
                momentValue={reminder.notificationEvent}
                intervalOptions={INTERVAL_OPTIONS}
                momentOptions={MOMENT_OPTIONS}
                id={`${index}-selector`}
                key={index}
                customLabelClass={index !== 0 && style.labelThinWeight}
                onChange={({ target: { value, name } }) => handleSelectChange(index, name, value)}
                onRemove={() => removeReminder(index)}
              />
            ))}
            <div className="gin-top3">
              <Button
                variant="outline"
                text={placementTestsContent.placement_test_notification_page_button_label}
                icon={{ component: <SVGIcon glyph={GLYPHS.ICON_PLUS} /> }}
                onClick={() => addReminder()}
              />
            </div>
          </div>
          <div className="gin-top6">
            <ValidationMessage state="information">
              <div className={style.noteText}>{placementTestsContent.placement_test_notification_page_note_text}</div>
            </ValidationMessage>
          </div>
        </>
      )}
    </div>
  );
}

NotificationsAndReminders.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  sendEmailsToStudents: PropTypes.bool.isRequired,
  notificationReminders: PropTypes.array.isRequired,
  initialState: PropTypes.object,
  onStateUpdate: PropTypes.func.isRequired
};

export default withLocalizedContent('placementTests')(NotificationsAndReminders);
