import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import Heading from '@oup/shared-front-end/src/components/Heading';
import ValidationMessage from '@oup/shared-front-end/src/components/ValidationMessage';

import Toggle from '@oup/shared-front-end/src/components/Toggle';
import LockIcon from '@oup/shared-front-end/src/svg/oup/icon-close-24.svg';
import { setEditedPlacementTest } from '../../../redux/reducers/placementTestSessionCreate';
import classStyles from '../CreateAPlacementTest/CreateAPlacementTest.scss';

import animationStyles from '../../ClassOnboardingWizard/animationUtils/animationStyles.scss';
import {
  getPlacementTestSessionRequest,
  updatePlacementTestSessionRequest
} from '../../../redux/actions/placementTestOnboardingWizard';
import { refreshPlacementTestList } from '../../../redux/actions/placementTests';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { featureIsEnabled } from '../../../globals/envSettings';
import { isDefaultReminders } from '../utils/helpers';

import styles from './PlacementTestShowResults.scss';
import wizardStyles from '../PlacementTestOnboardingWizard.scss';

function PlacementTestShowResults({
  placementTestSessionNameValue,
  editedPlacementTest,
  orgId,
  placementTestSessionIdCreated,
  placementTestSessionNamePending,
  getPlacementTestSessionRequestAction,
  placementTestSessionId,
  setEditedPlacementTestAction,
  showResultsToStudents,
  refreshPlacementTestListAction,
  placementTestSessionUpdated,
  localizedContent: { placementTests: placementTestsContent },
  updatePlacementTestSessionRequestAction,
  notificationReminders
}) {
  const { loading, nextStep, setNextStepDisabled, stepHandler } = useWizard();
  const checkingName = placementTestSessionNamePending ? animationStyles.loading : '';
  const canShowStudentResults = featureIsEnabled('show-test-results-to-students');

  setNextStepDisabled(loading || checkingName);

  const sessionId = placementTestSessionIdCreated || placementTestSessionId;

  useEffect(() => {
    if (!showResultsToStudents && editedPlacementTest?.placementTestType === 'OPT_YL') {
      setEditedPlacementTestAction({ showResultsToStudents: false });
    } else {
      setEditedPlacementTestAction({ showResultsToStudents: true });
    }
  }, []);

  useEffect(() => {
    if (placementTestSessionUpdated) {
      getPlacementTestSessionRequestAction({ orgId, sessionId });
      nextStep();

      if (!canShowStudentResults) {
        refreshPlacementTestListAction();
      }
    }
  }, [placementTestSessionUpdated]);

  stepHandler(async () => {
    if (placementTestSessionUpdated) {
      return;
    }

    updatePlacementTestSessionRequestAction({
      orgId,
      sessionId,
      placementTestSessionNameValue,
      placementTest: {
        isSetupCompleted: true,
        ...editedPlacementTest,
        ...(!canShowStudentResults && !isDefaultReminders(notificationReminders)
          ? { notificationReminders: editedPlacementTest?.notificationReminders }
          : {}),
        ...(canShowStudentResults ? { showResultsToStudents: editedPlacementTest.editedPlacementTest } : {}),
        ...(!canShowStudentResults ? { sendEmailsToStudents: editedPlacementTest.sendEmailsToStudents } : {})
      }
    });

    throw new Error();
  });

  const onToggle = () => setEditedPlacementTestAction({ showResultsToStudents: !showResultsToStudents });

  const ToggleComponent = useCallback(
    () => <Toggle onChange={onToggle} toggledIcon={<LockIcon />} toggled={showResultsToStudents} />,
    [showResultsToStudents]
  );

  return (
    <div
      data-testid="ONBOARDING_WIZARD_CREATE_A_PLACEMENT_TEST_SESSION_SHOW_RESULTS_CONTAINER"
      className={styles.stepContainer}
    >
      <Heading
        text={`${placementTestsContent.placement_test_show_results_page_title} ${placementTestSessionNameValue ||
          placementTestsContent.placement_test_notification_page_session_name_fallback}`}
        size="medium"
        className={styles.heading}
      />
      <div className={`${wizardStyles.stepContent} ${classStyles.placementTestCreateContainer}`}>
        <div className={`${wizardStyles.contentCentered} ${styles.showTestResultsColumn}`}>
          <div className={styles.customiseToggle}>
            <ToggleComponent />
            <span className={styles.showResultsToStudents}>
              {placementTestsContent.placement_test_show_results_page_toggle_label}
            </span>
          </div>
          <div className="gin-top6">
            <ValidationMessage state="information">
              <div className={styles.noteText}>{placementTestsContent.placement_test_show_results_note_text}</div>
            </ValidationMessage>
          </div>
        </div>
      </div>
    </div>
  );
}

PlacementTestShowResults.propTypes = {
  editedPlacementTest: PropTypes.object,
  orgId: PropTypes.string,
  placementTestSessionNameValue: PropTypes.string,
  placementTestSessionNamePending: PropTypes.bool,
  placementTestSessionIdCreated: PropTypes.bool,
  getPlacementTestSessionRequestAction: PropTypes.func,
  setEditedPlacementTestAction: PropTypes.func,
  placementTestSessionId: PropTypes.string,
  showResultsToStudents: PropTypes.bool,
  refreshPlacementTestListAction: PropTypes.func,
  placementTestSessionUpdated: PropTypes.bool,
  localizedContent: PropTypes.object.isRequired,
  updatePlacementTestSessionRequestAction: PropTypes.func,
  notificationReminders: PropTypes.array
};

export default compose(
  withLocalizedContent('placementTests'),
  connect(
    ({ placementTestSessionCreate, placementOnboardingWizard, identity }) => ({
      placementTestSessionNameValue: placementTestSessionCreate.placementTestSessionNameValue,
      placementTestSessionNamePending: placementOnboardingWizard.placementTestSessionNamePending,
      placementTestSessionUpdated: placementOnboardingWizard.placementTestSessionUpdated,
      userId: identity.userId,
      orgId: identity.currentOrganisationId,
      editedPlacementTest: placementTestSessionCreate.editedPlacementTest,
      placementTestSessionIdCreated: placementOnboardingWizard.placementTestSessionIdCreated,
      placementTestSessionId: placementTestSessionCreate.placementTestSessionId,
      showResultsToStudents: placementTestSessionCreate.placementTest.showResultsToStudents,
      notificationReminders: placementTestSessionCreate.placementTest.notificationReminders
    }),
    {
      getPlacementTestSessionRequestAction: getPlacementTestSessionRequest,
      refreshPlacementTestListAction: refreshPlacementTestList,
      setEditedPlacementTestAction: setEditedPlacementTest,
      updatePlacementTestSessionRequestAction: updatePlacementTestSessionRequest
    }
  )
)(PlacementTestShowResults);
