import React, { useEffect, useState } from 'react';
import { pick } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { HUB_SUPPORT_REGISTER_ORGANISATION_URL, ORG_REGISTRATION_CONTEXT } from '@oup/shared-node-browser/constants';
import { projectedSize, orgRoles } from '@oup/shared-node-browser/org';

import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import Select from '@oup/shared-front-end/src/components/Select/Select';
import ValidationMessage from '@oup/shared-front-end/src/components/ValidationMessage';
import TextInput from '@oup/shared-front-end/src/components/TextInput/TextInput';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import { ICON_ORG_SMALL, ICON_ORG_LARGE } from '@oup/shared-front-end/src/svg/oup/index';
import RadioButtonGroup from '@oup/shared-front-end/src/components/RadioButtonGroup';
import Heading from '@oup/shared-front-end/src/components/Heading';

import actions from '../../../redux/actions';
import withLocalizedContent from '../../../language/withLocalizedContent';
import InformationBanner from '../../InformationBanner/InformationBanner';
import { getCurrentPlatform } from '../../../utils/platform';

import styles from './NewAdminProvidesOrganizationDetails.scss';

const formDataHasAllRequiredFields = (formQuery, branch) => {
  if (branch === ORG_REGISTRATION_CONTEXT.CES && !formQuery?.projectedSize) {
    return true;
  }
  const isAnyFieldEmpty = Object.keys(formQuery).some(field => !formQuery[field]);

  return isAnyFieldEmpty;
};

function NewAdminProvidesOrganizationDetails({
  localizedContent: {
    selfRegisteredUserOnboardingWizard: selfRegisteredUserOnboardingWizardContent,
    countryCodes: countryCodesContent,
    registerOrgComponent: registerOrgComponentContent,
    registerOrgComponent: content
  },
  errors,
  validate,
  submitting,
  failure,
  branch,
  register,
  success
}) {
  const isPlacementCentre = branch === ORG_REGISTRATION_CONTEXT.PLACEMENT;
  const [failureValidationMessage, setFailureValidationMessage] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    countryCode: '',
    primaryEmail: '',
    role: orgRoles.SECONDARY_SCHOOL
  });

  const { stepHandler, nextStep, setNextStepDisabled } = useWizard();

  const getRegistrationContext = () => (isPlacementCentre ? branch : ORG_REGISTRATION_CONTEXT.CES);

  const getCreateOrgPayload = () => {
    const { name, ...restFormData } = formData;
    const payload = { ...restFormData, name, platformCode: getCurrentPlatform(), isLmsLtiTool: false };

    if (getRegistrationContext() === ORG_REGISTRATION_CONTEXT.PLACEMENT) {
      payload.placementCenter = true;
    }

    return payload;
  };

  stepHandler(() => {
    if (success) {
      return;
    }

    register(getCreateOrgPayload(), getRegistrationContext());
    throw new Error('Prevent wizard from moving to next step');
  });

  useEffect(() => {
    if (success && !submitting) nextStep();
  }, [success, submitting]);

  useEffect(() => {
    if (failure && !submitting) setFailureValidationMessage(true);
  }, [failure, submitting]);

  useEffect(() => {
    const nextStepDisabled =
      submitting ||
      !Object.values(errors).length ||
      Object.values(errors).some(val => val === true) ||
      formDataHasAllRequiredFields(formData, branch);

    setNextStepDisabled(nextStepDisabled);
  }, [errors, formData]);

  const handleChange = key => ({ target: { value } }) => {
    setFormData({
      ...formData,
      [key]: value
    });
  };

  const handleBlur = key => () => {
    validate({ [key]: formData[key] });
  };

  return (
    <div
      className={styles.registerOrgContentContainer}
      data-testid="SELF_REGISTERED_USER_ONBOARDING_WIZARD_STAFF__ROLE_PROVIDES_ORGANIZATION_DETAILS_CONTAINER"
    >
      <div className={styles.orgSetupStep}>
        <Heading
          text={selfRegisteredUserOnboardingWizardContent.self_registered_user_provides_org_details_title}
          size="medium"
          className={styles.heading}
        />
        <Heading
          text={selfRegisteredUserOnboardingWizardContent.self_registered_user_provides_org_details_subtitle}
          variant="h2"
          size="small"
          className={styles.subHeading}
        />

        {failureValidationMessage && (
          <div className="gin-bot3">
            <ValidationMessage state="error">{registerOrgComponentContent.validation_error_message}</ValidationMessage>
          </div>
        )}

        <div className={styles.orgSetupForm}>
          <TextInput
            id="name"
            label={selfRegisteredUserOnboardingWizardContent.self_registered_user_provides_org_details_name_label}
            placeholder={
              selfRegisteredUserOnboardingWizardContent.self_registered_user_provides_org_details_name_placeholder
            }
            value={formData.name}
            required
            onChange={handleChange('name')}
            onBlur={handleBlur('name')}
            state={(() => {
              if (typeof errors.name === 'boolean') {
                return !errors.name ? 'valid' : 'invalid';
              }

              return 'default';
            })()}
            validationMessage={errors.name ? registerOrgComponentContent.name_error : ''}
          />
          <Select
            id="countryCode"
            name="countryCode"
            label={selfRegisteredUserOnboardingWizardContent.self_registered_user_provides_org_details_country_label}
            value={formData.countryCode}
            options={[
              {
                value: '',
                text: selfRegisteredUserOnboardingWizardContent.self_registered_user_provides_org_country_placeholder
              },
              ...Object.entries(countryCodesContent).map(([value, text]) => ({ text, value }))
            ]}
            required
            onChange={handleChange('countryCode')}
            onBlur={handleBlur('countryCode')}
            state={(() => {
              if (typeof errors.countryCode === 'boolean') {
                return !errors.countryCode ? 'valid' : 'invalid';
              }
              return 'default';
            })()}
            validationMessage={errors.countryCode ? registerOrgComponentContent.country_error : ''}
          />
          <TextInput
            id="primaryEmail"
            name="primaryEmail"
            label={content.organization_primary_email_address}
            subLabel={content.primary_email_description_opt}
            placeholder={content.primary_email_placeholder}
            value={formData.primaryEmail}
            required
            autoComplete="email"
            onChange={handleChange('primaryEmail')}
            onBlur={handleBlur('primaryEmail')}
            state={(() => {
              if (typeof errors.primaryEmail === 'boolean') {
                return !errors.primaryEmail ? 'valid' : 'invalid';
              }

              return 'default';
            })()}
            validationMessage={errors.primaryEmail ? registerOrgComponentContent.primary_email_error : ''}
          />
          {!isPlacementCentre && (
            <div className={styles.fieldContainer}>
              <p>{content.organization_size}</p>
              <p>{content.organization_size_subtitle_opt}</p>
              <RadioButtonGroup
                classnames={{ container: styles.radioButtonGroupFieldset }}
                variant="outline"
                name="orgSizeSelect"
                legend={content.organization_size}
                checkedValue={[formData.projectedSize]}
                radioGroup={[
                  {
                    id: 'projectedSizeRegular',
                    label: content.organization_size_option_1,
                    value: projectedSize.REGULAR,
                    image: <ICON_ORG_SMALL className={styles.radioButtonImage} aria-hidden="true" />
                  },
                  {
                    id: 'projectedSizeLarge',
                    label: content.organization_size_option_2,
                    value: projectedSize.LARGE,
                    image: <ICON_ORG_LARGE className={styles.radioButtonImage} aria-hidden="true" />
                  }
                ]}
                onChange={handleChange('projectedSize')}
              />
            </div>
          )}
          {formData.projectedSize === projectedSize.LARGE ? (
            <div className={styles.infoBannerContainer}>
              <InformationBanner>
                <div className={styles.messageContainer}>
                  <p>{content.large_org_info}</p>
                  <TextLink to={content.org_limit_support_link} target="_blank">
                    {content.learn_more_about_limits}
                  </TextLink>
                </div>
              </InformationBanner>
            </div>
          ) : null}
        </div>
      </div>
      <div className={styles.asideContainer}>
        <h3>{content.aside_heading}</h3>
        <p>{content.aside_content}</p>
        <TextLink to={HUB_SUPPORT_REGISTER_ORGANISATION_URL} target="_blank">
          {content.aside_link_label}
        </TextLink>
      </div>
    </div>
  );
}

NewAdminProvidesOrganizationDetails.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  validate: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  failure: PropTypes.bool,
  branch: PropTypes.string,
  register: PropTypes.func.isRequired,
  success: PropTypes.bool
};

export default compose(
  withLocalizedContent('selfRegisteredUserOnboardingWizard', 'countryCodes', 'registerOrgComponent'),
  connect(
    state => ({
      ...pick(state.orgRegistration, ['errors', 'submitting', 'success', 'failure'])
    }),
    dispatch => ({
      validate: input => {
        dispatch(actions.validateOrgInput(input));
      },
      register: (input, branch) => {
        dispatch(actions.registerOrgRequest(input, branch));
      }
    })
  )
)(NewAdminProvidesOrganizationDetails);
